<template>
  <b-row>
    <b-col
      md="12"
      lg="12"
    >
      <b-card title="Détail de la préalerte ">
        <hr class="dashed">
        <b-row>
          <b-col
            md="6"
            lg="6"
          >
            <b-card-text>
              <b>Date : </b>{{ getDate(prealerte.createdAt) }}
            </b-card-text>

          </b-col>
          <b-col
            md="6"
            lg="6"
          >
            <b>Méssage:</b> {{ prealerte.commentType }}
          </b-col>

        </b-row>

        <b-row>
          <b-col
            md="6"
            lg="6"
          >
            <b-card-text>
              <b>Niveau d'eau : </b> <div :style="getColors(prealerte.code)">
                {{ " " }}
              </div>
            </b-card-text>

            <b-card-text>
              {{ getIndication(prealerte.code) }}
            </b-card-text>
          </b-col>
          <b-col
            md="6"
            lg="6"
          >
            <b>Commentaire :</b>
            <b-card-text v-if="prealerte.commentType==='TEXT'">
              {{ prealerte.commentText }}</b-card-text>
            <b-img
              v-else-if="prealerte.commentType==='IMAGE'"
              :src="getImage(prealerte.commentImage)"
              fluid
              alt="Responsive image"
            />
            <div v-else-if="prealerte.commentType==='AUDIO'">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="toggleSound(prealerte.commentAudio)"
              >
                Jouer l'audio
              </b-button>
              <b-button
                v-if="isPlaying"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="stopPlaying()"
              >
                stop
              </b-button>
            </div>
          </b-col>
        </b-row>

      </b-card>
    </b-col>

    <b-col
      md="6"
      lg="6"
    >
      <b-card title="Information sur l'utilisateur">
        <hr class="dashed">
        <b-row>
          <b-col
            md="6"
            lg="6"
          >
            <b>Nom et prénom : </b>  {{ prealerte.createdBy.lastName + ' '+ prealerte.createdBy.firstName }}
          </b-col>
          <b-col
            md="6"
            lg="6"
          >
            <b>Télephone : </b> {{ prealerte.createdBy.phone }}
          </b-col>
        </b-row>

        <b-row>
          <b-col
            md="6"
            lg="6"
          >
            <b>Email : </b>{{ prealerte.createdBy.email }}
          </b-col>
        </b-row>

      </b-card>
    </b-col>
    <b-col
      md="6"
      lg="6"
    >
      <b-card title="Information sur la balise">
        <hr class="dashed">
        <b-row>
          <b-col
            md="6"
            lg="6"
          >
            <b> Hameau : </b> {{ prealerte.balise.hameau }}
          </b-col>
            <b-col
            md="6"
            lg="6"
          >
            <b> Zone : </b> {{ prealerte.balise.zone.name }}
          </b-col>
          <b-col
            md="6"
            lg="6"
          >
            <b>Longitude : </b> {{ prealerte.balise.longitude }}
          </b-col>
          <b-col
            md="6"
            lg="6"
          >
            <b>Latitude : </b> {{ prealerte.balise.latitude }}
          </b-col>
        </b-row>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BImg, BButton,
} from 'bootstrap-vue'
import axiosInstance from '@/libs/axios'
import moment from 'moment'
import { Howl } from 'howler'

// const {Howl, Howler} = require('howler');

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BImg,
    BCol,
    BCardText,
  },
  data() {
    return {
      isPlaying: false,
      sound: null,
    }
  },
  computed: {
    prealerte() {
      return this.$store.state.mainAppStore.prealerte
    },
  },
  created() {
    console.log(this.$route.params.id)
    this.$store.dispatch('mainAppStore/getPrealertItem', this.$route.params.id).then(response => {
      console.log(response.data)
    })

      .catch(err => {
        console.error(err.response.headers)
      })
  },
  methods: {
    getIndication(code) {
      if (code === 'VERT') {
        return 'Tout va bien mais restez vigilant '
      }
      if (code === 'JAUNE') {
        return "L'eau vient - accélérez les récoltes, renfrocez les maisons, greniers et enclos, surveillez les enfanfs, personnes âgées et handicapées "
      }
      if (code === 'ORANGE') {
        return "L'eau est là - évitez les mauvais comportements, les déplacements non essentiels "
      }
      if (code === 'ROUGE') {
        return 'Danger - mobilisation pour le sauvetage '
      }
      return ''
    },
    getColors(code) {
      if (code === 'VERT') {
        return 'background-color:#2DB200;height:12px;'
      }
      if (code === 'JAUNE') {
        return 'background-color:#ffff26;height:12px;'
      }
      if (code === 'ORANGE') {
        return 'background-color:#FF8000;height:12px;'
      }
      if (code === 'ROUGE') {
        return 'background-color:#FF0000; height:12px;'
      }
      return ''
    },
    getImage(link) {
      // eslint-disable-next-line no-undef
      return `${axiosInstance.defaults.baseURL}/api/v1/${link}`
    },
    getAudio(link) {
      // eslint-disable-next-line no-undef
      return `${axiosInstance.defaults.baseURL}/api/v1/${link}`
    },
    getDate(date) {
      console.log(date)
      // moment.locale()
      moment.locale('fr')
      return moment(date).format('LLL')
      // return moment(date).format('DD-MM-YYYY HH:mm')
    },
    toggleSound(url) {
      // console.log(this.$refs)
      // const { audio } = this.$refs
      // if (
      //   audio.paused
      //     && document.querySelector('.toggle-sound').classList.contains('paused')
      // ) {
      //   console.log('play it')
      //   audio.play()
      //   document.querySelector('.toggle-sound').classList.remove('paused')
      // } else {
      //   console.log('pause it')
      //   audio.pause()
      //   document.querySelector('.toggle-sound').classList.add('paused')
      // }
      const link = this.getAudio(url)
      // eslint-disable-next-line no-var
      this.sound = new Howl({
        src: [link],
        format: ['mp3'],
        loop: true,
        volume: 0.5,
      })
      this.sound.play()
      this.isPlaying = true
      // Clear listener after first call.
      console.log('avant')
      this.sound.once('load', () => {
        console.log('dedans')
        this.sound.play()
      })

      // Fires when the sound finishes playing.
      this.sound.on('end', () => {
        console.log('Finished!')
        this.isPlaying = false
        this.sound.stop()
      })
    },
    stopPlaying() {
      this.isPlaying = false
      this.sound.stop()
    },
  },
}
</script>

<style>

</style>
